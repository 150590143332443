.header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  background-color: #282c34;
}

ol,
ul {
  padding-left: 30px;
}

.board {
  display: flex;
  flex-direction: column;
}

.square-container-5x5 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  grid-gap: 0px;
}

.square2 {
  position: relative;
  border: 1px solid;
  box-sizing: border-box;
}

.square2::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.square2 .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.guess-board {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  justify-self: flex-start;
}

.board-row {
  display: flex;
  flex-direction: row;
}

.status {
  margin-bottom: 10px;
}

.square {
  margin: -1px;
  padding: 0px;
  border: 1;
  text-align: center;
  vertical-align: middle;
  border-style: solid;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.square:focus {
  outline: none;
}

/* .kbd-navigation .square:focus {
  background: #ddd;
} */

.game {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: start;
  justify-items: center;
  background-color: #282c34;
}

.game-left {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #282c34;
}

.game-center {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
}

.game-right {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
}

.game-info {
  margin-left: 20px;
}

.keyboard {
  display: flex;
  flex-direction: column;
  margin: 2%;
}

.keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.key {
  background: #fff;
  border: 1px solid #999;
  border-radius: 4px;
  font-size: 3vmin;
  font-weight: bold;
  margin: 2px;
  text-align: center;
}

.modal-body {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.modal {
  background: #282c34;
  color: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.modal-header {
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.modal-content {
  padding: 1rem;
  flex: 1;
}

.modal-actions {
  border-top: 1px solid #ccc;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-button {
  border: 0;
  background: #78f89f;
  border-radius: 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 1;
}

.game-statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 2fr 1fr;
  gap: 10px;
}

.game-stats-text {
  margin: 0;
  padding: 0;
}

.game-stats-item {
  text-align: center;
}

.game-stats-item-1 {
  grid-row: 1;
  grid-column: 1;
}

.game-stats-item-2 {
  grid-row: 1;
  grid-column: 2;
}

.game-stats-item-3 {
  grid-row: 1;
  grid-column: 3;
}

.game-stats-item-4 {
  grid-row: 1;
  grid-column: 4;
}

.game-stats-distribution {
  grid-row: 2;
  grid-column: 1 / 5;
  display: flex;
  flex-direction: row;
}

.game-stats-item-5 {
  grid-row: 3;
  grid-column: 2;
}

.game-stats-item-6 {
  grid-row: 3;
  grid-column: 3;
}

.game-stats-distribution-bar {
  display: flex;
  flex-direction: column;
  font-size: 0.5rem;
  margin: 1px;
  text-align: center;
  flex-grow: 1;
}

.difficulty-selector {
  width: 50%;
  margin: 8px;
}
